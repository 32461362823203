import $ from "jquery";

(function() {
    $('[data-toggle="offcanvas"]').on('click', function () {
        let $el = $(this);
        let target = $el.data('target');
        let parent = $el.data('parent');
        let $target = $(target);
        let $parent = $(parent);
        $target.toggleClass('in');

        if ($target.hasClass('in') && !$parent.hasClass('modal-open')) {
            $parent.addClass('modal-open');
        } else {
            $parent.removeClass('modal-open');
        }
        if ($target.hasClass('in') && !$el.hasClass('open')) {
            $el.addClass('open');
        } else {
            $el.removeClass('open');
        }
    });
})();