import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faEdit as falEdit, 
	faQuoteLeft as falQuoteLeft, 
	faQuoteRight as falQuoteRight, 
	faCloudDownloadAlt as falCloudDownloadAlt, 
	faAngleDoubleRight as falAngleDoubleRight,
	faCheck as faCheckLight
} from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, 
	faCircle as farCircle, 
	faThumbsUp 
} from '@fortawesome/pro-regular-svg-icons';

import { 
	faChevronRight, 
	faChevronLeft, 
	faChevronDown, 
	faTimes, 
	faAngleRight, 
	faAngleLeft, 
	faAngleDown, 
	faAngleDoubleRight, 
	faMapMarkerAlt, 
	faStar, 
	faPhone, 
	faFax, 
	faEnvelope, 
	faCircle as fasCircle, 
	faSearch as fasSearch, 
	faChevronUp as fasChevronUp, 
	faLink as fasLink, 
	faDownload as fasDownload, 
	faEdit as fasEdit, 
	faSpinner as fasSpinner, 
	faCaretRight, 
	faCaretLeft, 
	faCaretDown, 
	faCaretUp,
	faArrowRight as faArrowRightSolid,
	faPlayCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faYoutube, faPinterestP, faGoogle, faGooglePlus } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(
	falEdit, 
	falQuoteLeft, 
	falQuoteRight, 
	falCloudDownloadAlt, 
	falAngleDoubleRight,
	faCheckLight,
	// regular
	farSearch, 
	farCircle, 
	faThumbsUp,
// solid
faArrowRightSolid,
	faChevronRight, 
	faAngleRight, 
	faPlayCircle,
	faChevronLeft, faChevronDown, faTimes, faAngleDoubleRight, faAngleDown, faAngleRight, faAngleLeft, fasCircle, fasSearch, fasChevronUp, fasLink, fasDownload, fasEdit, fasSpinner, faPhone, faFax, faEnvelope, faMapMarkerAlt, faStar, faCaretRight, faCaretLeft, faCaretDown, faCaretUp);
// brands
library.add(faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGoogle, faGooglePlus, faInstagram);

dom.i2svg();
dom.watch();