import $ from "jquery";
import "magnific-popup";
import "slick-carousel";

export default class UIBindings {

    formSubmitButton() {
        $(document).on('submit', 'form', function () {
            let submitText = $(this).find('[data-submit-text]');
            let submittingText = $(this).find('[data-submitting-text]');
            let btn = submitText.closest('button');
            
            submittingText.removeClass('d-none');
            submitText.addClass('d-none');
            btn.prop('disabled', true);
        });
    }


    bootstrap () {
    	$('[data-toggle="tooltip"]').tooltip()
    }

    menuShrink (){
        $(document).ready(function() {
          $(window).on("scroll", function() {
            if ($(window).scrollTop() >= 20) {                
                $('.alt-logo').removeClass('d-lg-none');
                $('.main-logo').removeClass('d-lg-block');
                $('.foundation-logo').removeClass('mt-lg-5');
                $('#mainNavigtion').removeClass('mt-lg-5');
            } else {
                $('.alt-logo').addClass('d-lg-none');
                $('.main-logo').addClass('d-lg-block');
                $('.foundation-logo').addClass('d-lg-block mt-lg-5');
                $('#mainNavigtion').addClass('mt-lg-5');
            }
          });
        });
    }

}