import '../css/main.scss';
import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';

import "bootstrap";
import "../js/fontawesome";
import "../js/glide";
import "../js/magnificpopup";
import "../js/offcanvas";
import "../js/jquery.validate";
import "../js/donate";

import UIBindings from "../js/ui-bindings";
import { Forms } from "../js/form";
import { ExternalLinks } from "../js/externallinks";
import { PageTimer } from "../js/pagetimer";
import { ShareHandler } from "../js/share";
import { set } from 'lodash';

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();

const forms = new Forms();
forms.validate();

const UIBinding = new UIBindings();
UIBinding.formSubmitButton();
UIBinding.bootstrap();
UIBinding.menuShrink();

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

$(window).on('load', function() {
    let bannerImage = document.getElementById("noticeBanner");
    if (bannerImage && window.location.pathname == '/') {
        $.magnificPopup.open({
        items: { src: '#noticeBanner' },
        type: 'inline',
        }, 0);
    }
});